:root {
  --smallerSpace: 60px;
}
@media (max-width: 1197px) {
  :root {
    --smallerSpace: 40px;
  }
}
:root {
  --deskWidth: 1406px;
  --deskSpace: 80px;
  --deskMaxWidth: calc(100vw - (2 * var(--deskSpace)));
}
@media (max-width: 1197px) {
  :root {
    --deskSpace: 50px;
  }
}
@media (max-width: 767px) {
  :root {
    --deskSpace: 36px;
  }
}
:root {
  --spacePart: 20px;
}
@media (max-width: 1197px) {
  :root {
    --spacePart: 10px;
  }
}
@media (max-width: 767px) {
  :root {
    --spacePart: 10px;
  }
}
:root {
  --spaceUnit: 40px;
}
@media (max-width: 1197px) {
  :root {
    --spaceUnit: 30px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceUnit: 20px;
  }
}
:root {
  --spaceTotal: 60px;
}
@media (max-width: 1197px) {
  :root {
    --spaceTotal: 40px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceTotal: 30px;
  }
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: #e5005a;
  font-size: 19px;
  line-height: 1.47368421;
  letter-spacing: 0.025em;
  font-weight: 700;
  cursor: pointer;
  color: #fff;
  padding: 11px 40px;
  -webkit-appearance: none;
  border-radius: 10000000px;
  box-sizing: border-box;
  text-align: left;
  text-align: center;
  text-decoration: none;
  background-image: none !important;
}
.button:hover,
.button:focus {
  background-color: #F9B232;
}
@media (max-width: 1197px) {
  .button {
    font-size: 14px;
    line-height: 1.14285714;
    padding: 9px 20px;
  }
}
div.navi {
  float: left;
}
div.navi > .item {
  float: left;
}
div.navi > .item > .menu {
  display: block;
}
@media (min-width: 1198px) {
  .togglenavigation,
  .mobile-navigation {
    display: none;
  }
}
.navigation div.sub1 > .item {
  margin-right: 46px;
  position: relative;
  padding-bottom: 36px;
}
.navigation div.sub1 > .item.exit {
  margin-right: 0 !important;
}
.navigation div.sub1 > .item:hover > .menu:after,
.navigation div.sub1 > .item:focus > .menu:after {
  opacity: 1;
  transform: scale(1);
  transition: all 0.4s cubic-bezier(0.11, 1.31, 0.57, 1.24);
}
.navigation div.sub1 > .item > .menu {
  font-size: 20px;
  line-height: 1.2;
  text-decoration: none;
  letter-spacing: 0.03em;
  color: #212526;
  padding-top: 20px;
  position: relative;
}
.navigation div.sub1 > .item > .menu:after {
  content: '';
  position: absolute;
  left: 50%;
  top: 0;
  margin-left: -6px;
  transform: ;
  transform: scale(0);
  opacity: 0;
  z-index: 2;
  width: 12px;
  height: 12px;
  border-radius: 10000px;
  transition: all 0.3s cubic-bezier(0.33, -0.54, 0.6, 0.27);
}
.navigation div.sub1 > .item > .menu.path:after {
  opacity: 1;
  transform: scale(1);
  transition: all 0.4s cubic-bezier(0.11, 1.31, 0.57, 1.24);
}
@media (max-width: 1449px) {
  .navigation div.sub1 > .item {
    margin-right: 38px;
  }
  .navigation div.sub1 > .item > .menu {
    font-size: 17px;
    line-height: 1.41176471;
  }
}
.navigation div.sub1 > .item.cb-navi-target-naviItem--yellow > .menu:after {
  background-color: #fcd48c;
}
.navigation div.sub1 > .item.cb-navi-target-naviItem--red > .menu:after {
  background-color: #f173a4;
}
.navigation div.sub1 > .item.cb-navi-target-naviItem--lightblue > .menu:after {
  background-color: #CDE9E8;
}
.navigation div.sub1 > .item.cb-navi-target-naviItem--blue > .menu:after {
  background-color: #fcd48c;
}
.navigation div.sub1 > .item.cb-navi-target-naviItem--green > .menu:after {
  background-color: #D3E0BD;
}
.navigation div.sub1 > .item:before,
.navigation div.sub1 > .item:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 4;
  border-style: solid;
  border-color: transparent;
  border-width: 0 15px 22px 15px;
  display: none;
}
.navigation div.sub1 > .item:before {
  border-bottom-color: #737B7D;
}
.navigation div.sub1 > .item:after {
  bottom: -2px;
  border-bottom-color: #fff;
}
.navigation div.sub1 > .item.item-empty:after,
.navigation div.sub1 > .item.item-empty:before {
  display: none !important;
}
.navigation div.sub2 {
  position: absolute;
  left: 0;
  top: 100%;
  background-color: #fff;
  z-index: 3;
  flex-direction: column;
  border: 1px solid #737B7D;
  display: none;
}
.navigation div.sub2 > .item {
  position: relative;
  z-index: 5;
}
.navigation div.sub2 > .item > .menu {
  white-space: nowrap;
  text-decoration: none;
  font-size: 17px;
  line-height: 1.17647059;
  letter-spacing: 0.03em;
  padding: 25px;
  color: #212526;
}
.navigation div.sub2 > .item > .menu:hover,
.navigation div.sub2 > .item > .menu:focus {
  background-color: #F0F0F0;
}
.navigation div.sub2 > .item > .menu.path {
  background-color: #F0F0F0;
  font-weight: 500;
}
@media (max-width: 1449px) {
  .navigation div.sub2 > .item > .menu {
    font-size: 15px;
    line-height: 1.33333333;
    padding: 20px;
  }
}
.navigation div.sub1 > .item:hover:before,
.navigation div.sub1 > .item:hover:after {
  display: block;
}
.navigation div.sub1 > .item:hover div.sub2 {
  display: flex;
}
#home {
  transition: all 0.4s;
}
.cb-scroll-triggered--active #home {
  height: 60px;
  margin-top: 50px;
  margin-bottom: 30px;
}
.navigation {
  transition: all 0.4s;
}
.cb-scroll-triggered--active .navigation {
  margin-top: 43px;
}
.section--smallMood .subcontent--1 {
  width: 582px;
  padding-left: 80px;
  box-sizing: border-box;
}
.section--smallMood .subcontent--2 {
  width: calc(100% - 602px);
}
@media (max-width: 1449px) {
  .section--smallMood .subcontent--1 {
    width: 460px;
    padding-left: 0;
  }
  .section--smallMood .subcontent--2 {
    width: calc(100% - 500px);
  }
}
.area--one {
  width: 100%;
}
.area--one > .unit {
  margin-right: 4.15194346%;
  margin-left: 4.15194346%;
  width: 91.69611307%;
}
.area--one .cb-album .body,
.area--one .head,
.area--one .foot,
.area--one .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area--one > .slim {
  width: 41.69611307%;
}
.area--one > .slim .head,
.area--one > .slim .foot,
.area--one > .slim .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area--one > .slim.cb-album .body {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area--one > .slim .tiny {
  width: 100%;
}
.area--two {
  width: 100%;
}
.area--two > .unit {
  margin-right: 1.58402204%;
  margin-left: 1.58402204%;
  width: 96.83195592%;
}
.area--two .cb-album .body,
.area--two .head,
.area--two .foot,
.area--two .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area--two > .slim {
  width: 30.16528926%;
}
.area--two > .slim .head,
.area--two > .slim .foot,
.area--two > .slim .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area--two > .slim.cb-album .body {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area--two > .slim .tiny {
  width: 100%;
}
.area--three {
  width: 100%;
}
.area--three > .unit {
  margin-right: 1.04456825%;
  margin-left: 1.04456825%;
  width: 97.91086351%;
}
.area--three .cb-album .body,
.area--three .head,
.area--three .foot,
.area--three .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area--three > .slim {
  width: 22.91086351%;
}
.area--three > .slim .head,
.area--three > .slim .foot,
.area--three > .slim .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area--three > .slim.cb-album .body {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area--three > .slim .tiny {
  width: 100%;
}
.area--six {
  width: 100%;
}
.area--six > .unit {
  margin-right: 5.10855683%;
  margin-left: 5.10855683%;
  width: 89.78288633%;
}
.area--six .cb-album .body,
.area--six .head,
.area--six .foot,
.area--six .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area--six > .slim {
  width: 23.11621967%;
}
.area--six > .slim .head,
.area--six > .slim .foot,
.area--six > .slim .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area--six > .slim.cb-album .body {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area--six > .slim .tiny {
  width: 100%;
}
.area--one {
  width: 109.05587669%;
  margin-left: -4.52793834%;
}
.area--two {
  width: 103.27169275%;
  margin-left: -1.63584637%;
}
.area--three {
  width: 102.13371266%;
  margin-left: -1.06685633%;
}
.area--six {
  width: 111.37980085%;
  margin-left: -5.68990043%;
}
.area--one .pure.wide .body {
  width: 109.05587669%;
  margin-left: -4.52793834%;
}
.area--one .pure.wide .part {
  margin-left: 4.15194346%;
  margin-right: 4.15194346%;
  width: 91.69611307%;
}
.area--one .pure.wide .part.tiny {
  width: 41.69611307%;
}
.area--one .pure.wide .pict.tall {
  padding-right: 100px;
}
.area--one .flat {
  width: 91.69611307%;
}
.area--two .seam {
  width: 30.16528926%;
}
.area--two .flat {
  width: 96.83195592%;
}
.area--three .seam,
.area--three .flat {
  width: 22.91086351%;
}
.area--four .unit.wide {
  width: 55.10597303%;
}
.area--four .unit.wide:first-child {
  width: 100%;
}
.area--four .unit.slim {
  width: 37.57225434%;
}
.area--five .unit .body {
  width: 496px;
  max-width: calc(100% - 160px);
}
.area--five .unit.wide {
  padding-left: 50%;
}
.area--five .unit.slim {
  padding-right: 50%;
}
.area--six .pure.slim {
  width: 89.78288633%;
  max-width: 1038px;
}
.area--six .seam {
  width: 23.11621967%;
}
/*# sourceMappingURL=./screen-large.css.map */